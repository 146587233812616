import React, { useState } from 'react';
import { 
  CreditCard, 
  Plus, 
  Trash2, 
  Edit2, 
  Clock, 
  Download,
  CheckCircle,
  AlertTriangle,
  DollarSign,
  Calendar,
  ArrowDownLeft,
  ArrowUpRight,
  Filter,
  Search,
  Eye,
  EyeOff,
  Loader2
} from 'lucide-react';

interface PaymentMethod {
  id: string;
  type: 'credit' | 'debit';
  cardNumber: string;
  cardHolder: string;
  expiryDate: string;
  isDefault: boolean;
}

interface Transaction {
  id: string;
  type: 'payment' | 'refund';
  amount: number;
  date: string;
  description: string;
  status: 'completed' | 'pending' | 'failed';
  paymentMethod: string;
}

const initialPaymentMethods: PaymentMethod[] = [
  {
    id: '1',
    type: 'credit',
    cardNumber: '4532 •••• •••• 1234',
    cardHolder: 'AHMET YILMAZ',
    expiryDate: '12/25',
    isDefault: true
  },
  {
    id: '2',
    type: 'debit',
    cardNumber: '5421 •••• •••• 5678',
    cardHolder: 'AHMET YILMAZ',
    expiryDate: '09/24',
    isDefault: false
  }
];

const initialTransactions: Transaction[] = [
  {
    id: 't1',
    type: 'payment',
    amount: 2500,
    date: '2024-03-27T10:30:00',
    description: 'Kapadokya Turu Rezervasyonu',
    status: 'completed',
    paymentMethod: '4532 •••• •••• 1234'
  },
  {
    id: 't2',
    type: 'refund',
    amount: 1200,
    date: '2024-03-25T15:45:00',
    description: 'İptal Edilen Rezervasyon İadesi',
    status: 'completed',
    paymentMethod: '5421 •••• •••• 5678'
  }
];

export default function Payments() {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>(initialPaymentMethods);
  const [transactions, setTransactions] = useState<Transaction[]>(initialTransactions);
  const [showAddCard, setShowAddCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [showCardNumber, setShowCardNumber] = useState<Record<string, boolean>>({});
  const [filterStatus, setFilterStatus] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');

  const handleAddCard = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      // Simüle edilmiş kart ekleme işlemi
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      setSuccess('Kart başarıyla eklendi');
      setShowAddCard(false);
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError('Kart eklenirken bir hata oluştu');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteCard = async (cardId: string) => {
    if (!window.confirm('Bu kartı silmek istediğinizden emin misiniz?')) {
      return;
    }

    try {
      setPaymentMethods(methods => methods.filter(method => method.id !== cardId));
      setSuccess('Kart başarıyla silindi');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError('Kart silinirken bir hata oluştu');
    }
  };

  const handleSetDefaultCard = async (cardId: string) => {
    try {
      setPaymentMethods(methods =>
        methods.map(method => ({
          ...method,
          isDefault: method.id === cardId
        }))
      );
      setSuccess('Varsayılan kart güncellendi');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError('Varsayılan kart güncellenirken bir hata oluştu');
    }
  };

  const downloadTransactions = () => {
    const csvContent = [
      ['Tarih', 'İşlem', 'Tutar', 'Açıklama', 'Durum', 'Ödeme Yöntemi'],
      ...transactions.map(t => [
        new Date(t.date).toLocaleDateString('tr-TR'),
        t.type === 'payment' ? 'Ödeme' : 'İade',
        `${t.amount} ₺`,
        t.description,
        t.status === 'completed' ? 'Tamamlandı' : t.status === 'pending' ? 'Beklemede' : 'Başarısız',
        t.paymentMethod
      ])
    ].map(row => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `transactions_${new Date().toISOString().split('T')[0]}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Header */}
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-gray-900">Ödeme Yönetimi</h1>
          <p className="mt-1 text-sm text-gray-500">
            Ödeme yöntemlerinizi yönetin ve işlem geçmişinizi görüntüleyin
          </p>
        </div>

        {/* Alerts */}
        {error && (
          <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4 rounded flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700">{error}</p>
          </div>
        )}

        {success && (
          <div className="mb-6 bg-green-50 border-l-4 border-green-500 p-4 rounded flex items-center">
            <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-700">{success}</p>
          </div>
        )}

        {/* Payment Methods */}
        <div className="bg-white rounded-xl shadow-sm p-6 mb-8">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-lg font-semibold">Kayıtlı Kartlar</h2>
            <button
              onClick={() => setShowAddCard(true)}
              className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              <Plus className="h-5 w-5 mr-2" />
              Yeni Kart Ekle
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {paymentMethods.map((method) => (
              <div 
                key={method.id}
                className={`p-4 border rounded-lg ${
                  method.isDefault ? 'border-blue-200 bg-blue-50' : 'border-gray-200'
                }`}
              >
                <div className="flex justify-between items-start mb-4">
                  <div className="flex items-center">
                    <CreditCard className={`h-6 w-6 ${
                      method.isDefault ? 'text-blue-600' : 'text-gray-400'
                    }`} />
                    <div className="ml-3">
                      <div className="flex items-center">
                        <span className="text-sm font-medium text-gray-900">
                          {method.type === 'credit' ? 'Kredi Kartı' : 'Banka Kartı'}
                        </span>
                        {method.isDefault && (
                          <span className="ml-2 px-2 py-1 text-xs font-medium text-blue-600 bg-blue-100 rounded-full">
                            Varsayılan
                          </span>
                        )}
                      </div>
                      <p className="text-sm text-gray-500">{method.cardHolder}</p>
                    </div>
                  </div>
                  <div className="flex space-x-2">
                    {!method.isDefault && (
                      <button
                        onClick={() => handleSetDefaultCard(method.id)}
                        className="p-1 text-gray-400 hover:text-blue-600"
                        title="Varsayılan Yap"
                      >
                        <CheckCircle className="h-5 w-5" />
                      </button>
                    )}
                    <button
                      onClick={() => handleDeleteCard(method.id)}
                      className="p-1 text-gray-400 hover:text-red-600"
                      title="Kartı Sil"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <span className="font-mono text-gray-900">
                      {method.cardNumber}
                    </span>
                    <button
                      onClick={() => setShowCardNumber(prev => ({
                        ...prev,
                        [method.id]: !prev[method.id]
                      }))}
                      className="ml-2 text-gray-400 hover:text-gray-600"
                    >
                      {showCardNumber[method.id] ? (
                        <EyeOff className="h-4 w-4" />
                      ) : (
                        <Eye className="h-4 w-4" />
                      )}
                    </button>
                  </div>
                  <span className="text-sm text-gray-500">
                    Son Kullanım: {method.expiryDate}
                  </span>
                </div>
              </div>
            ))}
          </div>

          {/* Add Card Modal */}
          {showAddCard && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
              <div className="bg-white rounded-xl shadow-xl p-6 w-full max-w-md">
                <div className="flex justify-between items-center mb-6">
                  <h3 className="text-lg font-semibold">Yeni Kart Ekle</h3>
                  <button
                    onClick={() => setShowAddCard(false)}
                    className="text-gray-400 hover:text-gray-600"
                  >
                    <Trash2 className="h-5 w-5" />
                  </button>
                </div>

                <form onSubmit={handleAddCard} className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Kart Numarası
                    </label>
                    <input
                      type="text"
                      placeholder="•••• •••• •••• ••••"
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Kart Üzerindeki İsim
                    </label>
                    <input
                      type="text"
                      placeholder="Kart üzerindeki isim"
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Son Kullanım Tarihi
                      </label>
                      <input
                        type="text"
                        placeholder="AA/YY"
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        CVV
                      </label>
                      <input
                        type="text"
                        placeholder="•••"
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      />
                    </div>
                  </div>

                  <div className="flex items-center mt-4">
                    <input
                      type="checkbox"
                      id="makeDefault"
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    />
                    <label htmlFor="makeDefault" className="ml-2 text-sm text-gray-600">
                      Bu kartı varsayılan olarak ayarla
                    </label>
                  </div>

                  <div className="flex justify-end space-x-3 mt-6">
                    <button
                      type="button"
                      onClick={() => setShowAddCard(false)}
                      className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
                    >
                      İptal
                    </button>
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50"
                    >
                      {isLoading ? (
                        <Loader2 className="h-5 w-5 animate-spin" />
                      ) : (
                        'Kartı Kaydet'
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>

        {/* Transactions */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-lg font-semibold">İşlem Geçmişi</h2>
            <div className="flex space-x-4">
              <div className="relative">
                <input
                  type="text"
                  placeholder="İşlem ara..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              </div>
              <select
                value={filterStatus}
                onChange={(e) => setFilterStatus(e.target.value)}
                className="px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option value="all">Tüm İşlemler</option>
                <option value="payment">Ödemeler</option>
                <option value="refund">İadeler</option>
              </select>
              <button
                onClick={downloadTransactions}
                className="flex items-center px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
              >
                <Download className="h-5 w-5 mr-2" />
                Dışa Aktar
              </button>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Tarih
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    İşlem
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Tutar
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Açıklama
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Durum
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Ödeme Yöntemi
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {transactions.map((transaction) => (
                  <tr key={transaction.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <Calendar className="h-5 w-5 text-gray-400 mr-2" />
                        <span className="text-sm text-gray-900">
                          {new Date(transaction.date).toLocaleDateString('tr-TR')}
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        {transaction.type === 'payment' ? (
                          <ArrowUpRight className="h-5 w-5 text-red-500 mr-2" />
                        ) : (
                          <ArrowDownLeft className="h-5 w-5 text-green-500 mr-2" />
                        )}
                        <span className="text-sm text-gray-900">
                          {transaction.type === 'payment' ? 'Ödeme' : 'İade'}
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <DollarSign className="h-5 w-5 text-gray-400 mr-1" />
                        <span className={`text-sm font-medium ${
                          transaction.type === 'payment' ? 'text-red-600' : 'text-green-600'
                        }`}>
                          {transaction.type === 'payment' ? '-' : '+'}
                          {transaction.amount.toLocaleString('tr-TR')} ₺
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="text-sm text-gray-900">
                        {transaction.description}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                        transaction.status === 'completed'
                          ? 'bg-green-100 text-green-800'
                          : transaction.status === 'pending'
                          ? 'bg-yellow-100 text-yellow-800'
                          : 'bg-red-100 text-red-800'
                      }`}>
                        {transaction.status === 'completed' ? 'Tamamlandı' :
                         transaction.status === 'pending' ? 'Beklemede' : 'Başarısız'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <CreditCard className="h-5 w-5 text-gray-400 mr-2" />
                        <span className="text-sm text-gray-900">
                          {transaction.paymentMethod}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}