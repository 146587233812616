import React from 'react';
import { Cpu, MemoryStick as Memory, Database, Network } from 'lucide-react';

interface SystemHealthProps {
  status: {
    cpu: number;
    memory: number;
    disk: number;
    network: number;
  };
}

export default function SystemHealth({ status }: SystemHealthProps) {
  return (
    <div className="space-y-4">
      <h3 className="text-sm font-medium text-gray-500">Sistem Durumu</h3>
      
      <div className="space-y-3">
        <div>
          <div className="flex justify-between items-center mb-1">
            <div className="flex items-center">
              <Cpu className="h-4 w-4 text-blue-600 mr-2" />
              <span className="text-sm text-gray-600">CPU</span>
            </div>
            <span className="text-sm text-gray-900">{status.cpu}%</span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div 
              className="bg-blue-600 h-2 rounded-full transition-all duration-500" 
              style={{ width: `${status.cpu}%` }}
            />
          </div>
        </div>

        <div>
          <div className="flex justify-between items-center mb-1">
            <div className="flex items-center">
              <Memory className="h-4 w-4 text-green-600 mr-2" />
              <span className="text-sm text-gray-600">Bellek</span>
            </div>
            <span className="text-sm text-gray-900">{status.memory}%</span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div 
              className="bg-green-600 h-2 rounded-full transition-all duration-500" 
              style={{ width: `${status.memory}%` }}
            />
          </div>
        </div>

        <div>
          <div className="flex justify-between items-center mb-1">
            <div className="flex items-center">
              <Database className="h-4 w-4 text-yellow-600 mr-2" />
              <span className="text-sm text-gray-600">Disk</span>
            </div>
            <span className="text-sm text-gray-900">{status.disk}%</span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div 
              className="bg-yellow-600 h-2 rounded-full transition-all duration-500" 
              style={{ width: `${status.disk}%` }}
            />
          </div>
        </div>

        <div>
          <div className="flex justify-between items-center mb-1">
            <div className="flex items-center">
              <Network className="h-4 w-4 text-purple-600 mr-2" />
              <span className="text-sm text-gray-600">Ağ</span>
            </div>
            <span className="text-sm text-gray-900">{status.network}%</span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div 
              className="bg-purple-600 h-2 rounded-full transition-all duration-500" 
              style={{ width: `${status.network}%` }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}