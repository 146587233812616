import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Instagram, Twitter } from 'lucide-react';

export default function Footer() {
  return (
    <footer className="bg-blue-900 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <div className="flex items-center space-x-2 mb-4">
              <h3 className="text-xl font-bold">Turla</h3>
            </div>
            <p className="text-blue-200">
              Türkiye'nin eşsiz güzelliklerini keşfetmeniz için yanınızdayız.
            </p>
          </div>
          
          <div>
            <h4 className="text-lg font-semibold mb-4">Hızlı Bağlantılar</h4>
            <ul className="space-y-2">
              <li><Link to="/destinations" className="text-blue-200 hover:text-white">Destinasyonlar</Link></li>
              <li><Link to="/tours" className="text-blue-200 hover:text-white">Turlar</Link></li>
              <li><Link to="/experiences" className="text-blue-200 hover:text-white">Deneyimler</Link></li>
              <li><Link to="/about" className="text-blue-200 hover:text-white">Hakkımızda</Link></li>
            </ul>
          </div>
          
          <div>
            <h4 className="text-lg font-semibold mb-4">İletişim</h4>
            <ul className="space-y-2 text-blue-200">
              <li>Levent Mah. Büyükdere Cad. No:201</li>
              <li>Şişli, İstanbul</li>
              <li>Tel: (0212) 555 0123</li>
              <li>E-posta: info@turla.com</li>
            </ul>
          </div>
          
          <div>
            <h4 className="text-lg font-semibold mb-4">Bizi Takip Edin</h4>
            <div className="flex space-x-4">
              <a href="#" className="text-blue-200 hover:text-white transition-colors">
                <Facebook className="h-6 w-6" />
              </a>
              <a href="https://www.instagram.com/turlaturkiye/" target="_blank" rel="noopener noreferrer" className="text-blue-200 hover:text-white transition-colors">
                <Instagram className="h-6 w-6" />
              </a>
              <a href="#" className="text-blue-200 hover:text-white transition-colors">
                <Twitter className="h-6 w-6" />
              </a>
            </div>
            <div className="mt-4">
              <img 
                src="https://tursab.org.tr/assets/images/tursab-logo-light.png" 
                alt="TÜRSAB Logo" 
                className="h-12"
              />
            </div>
          </div>
        </div>
        
        <div className="border-t border-blue-800 mt-8 pt-8 text-center text-blue-200">
          <p>&copy; {new Date().getFullYear()} Turla. Tüm hakları saklıdır.</p>
        </div>
      </div>
    </footer>
  );
}