import React from 'react';
import { Star } from 'lucide-react';

const testimonials = [
  {
    id: 1,
    name: 'Ayşe Yılmaz',
    image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330',
    rating: 5,
    text: 'Kapadokya turu muhteşemdi! Rehberimiz çok bilgiliydi ve program mükemmel planlanmıştı.',
    tour: 'Kapadokya Turu'
  },
  {
    id: 2,
    name: 'Mehmet Demir',
    image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e',
    rating: 5,
    text: 'İstanbul Boğaz turu beklentilerimin çok üzerindeydi. Tekne çok konforluydu ve manzara muhteşemdi.',
    tour: 'İstanbul Boğaz Turu'
  },
  {
    id: 3,
    name: 'Zeynep Kaya',
    image: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80',
    rating: 5,
    text: 'Pamukkale\'de geçirdiğimiz gün unutulmazdı. Antik havuzda yüzmek çok keyifliydi.',
    tour: 'Pamukkale Turu'
  }
];

export default function Testimonials() {
  return (
    <section className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Misafirlerimizin Yorumları
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Turlarımıza katılan misafirlerimizin deneyimlerini dinleyin.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonials.map((testimonial) => (
            <div
              key={testimonial.id}
              className="bg-white rounded-xl shadow-lg p-6 transition-transform hover:scale-105"
            >
              <div className="flex items-center mb-4">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="h-12 w-12 rounded-full object-cover"
                />
                <div className="ml-4">
                  <h3 className="font-semibold text-gray-900">{testimonial.name}</h3>
                  <p className="text-sm text-gray-500">{testimonial.tour}</p>
                </div>
              </div>

              <div className="flex mb-4">
                {[...Array(5)].map((_, i) => (
                  <Star
                    key={i}
                    className={`h-5 w-5 ${
                      i < testimonial.rating
                        ? 'text-yellow-400 fill-current'
                        : 'text-gray-300'
                    }`}
                  />
                ))}
              </div>

              <p className="text-gray-600">{testimonial.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}