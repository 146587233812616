import React from 'react';
import { 
  UtensilsCrossed, 
  Paintbrush, 
  Music, 
  Coffee,
  Sunrise,
  Camera,
  Heart,
  Users
} from 'lucide-react';

const experiences = [
  {
    icon: UtensilsCrossed,
    title: 'Yöresel Mutfak',
    description: 'Geleneksel tarifler ve pişirme teknikleri',
    image: 'https://images.unsplash.com/photo-1662376569736-92b2587e9cdd',
    color: 'bg-red-50 text-red-600',
    participants: '2.5K+'
  },
  {
    icon: Paintbrush,
    title: 'El Sanatları',
    description: 'Çini, kilim ve geleneksel el işleri',
    image: 'https://images.unsplash.com/photo-1528484786961-e60836c28afb',
    color: 'bg-purple-50 text-purple-600',
    participants: '1.8K+'
  },
  {
    icon: Coffee,
    title: 'Kahve Kültürü',
    description: 'Türk kahvesi ritüeli ve falcılık',
    image: 'https://images.unsplash.com/photo-1589750602846-60028879da9b',
    color: 'bg-amber-50 text-amber-600',
    participants: '3.2K+'
  },
  {
    icon: Camera,
    title: 'Fotoğraf Turları',
    description: 'En güzel manzaralar ve anlar',
    image: 'https://images.unsplash.com/photo-1452587925148-ce544e77e70d',
    color: 'bg-blue-50 text-blue-600',
    participants: '2.1K+'
  }
];

export default function LocalExperiences() {
  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Yerel Deneyimler
          </h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Türk kültürünü yakından tanımak için benzersiz fırsatlar
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {experiences.map((exp) => (
            <div
              key={exp.title}
              className="group bg-white rounded-xl shadow-sm hover:shadow-lg transition-all duration-300 overflow-hidden"
            >
              <div className="relative aspect-[4/3]">
                <img
                  src={exp.image}
                  alt={exp.title}
                  className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-500"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                
                <div className="absolute top-4 right-4">
                  <div className="flex items-center space-x-2">
                    <span className="px-3 py-1 bg-white/90 backdrop-blur-sm rounded-full text-xs font-medium text-gray-700">
                      <Users className="h-3 w-3 inline-block mr-1" />
                      {exp.participants}
                    </span>
                  </div>
                </div>

                <div className="absolute bottom-4 left-4 right-4">
                  <div className={`${exp.color} w-10 h-10 rounded-full flex items-center justify-center mb-3`}>
                    <exp.icon className="h-5 w-5" />
                  </div>
                  <h3 className="text-xl font-bold text-white mb-1">
                    {exp.title}
                  </h3>
                  <p className="text-sm text-white/90">
                    {exp.description}
                  </p>
                </div>
              </div>

              <div className="p-4 flex items-center justify-between">
                <button className="text-sm text-blue-600 hover:text-blue-700 font-medium">
                  Detaylı Bilgi
                </button>
                <button className="p-2 hover:bg-gray-50 rounded-full transition-colors">
                  <Heart className="h-5 w-5 text-red-500" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}