import React from 'react';
import { User } from 'lucide-react';

export default function RecentUsers() {
  return (
    <div className="flow-root">
      <div className="text-center py-8 text-gray-500">
        <User className="h-12 w-12 mx-auto text-gray-400 mb-4" />
        <p>Henüz kayıtlı kullanıcı bulunmuyor</p>
      </div>
    </div>
  );
}