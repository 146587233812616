import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdminAuthMiddleware from './middleware/AdminAuthMiddleware';
import AuthMiddleware from './middleware/AuthMiddleware';
import CookieConsent from './components/CookieConsent';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import CityDetails from './pages/CityDetails';
import Profile from './pages/Profile';
import Payments from './pages/Payments';
import Campaigns from './pages/Campaigns';
import AdminLogin from './pages/admin/Login';
import Dashboard from './pages/admin/Dashboard';
import Users from './pages/admin/Users';
import Tours from './pages/admin/Tours';
import Bookings from './pages/admin/Bookings';
import Reviews from './pages/admin/Reviews';
import Settings from './pages/admin/Settings';
import AdminLayout from './components/admin/AdminLayout';
import Destinations from './pages/Destinations';
import Experiences from './pages/Experiences';
import About from './pages/About';

function App() {
  return (
    <Router>
      <AdminAuthMiddleware>
        <AuthMiddleware>
          <CookieConsent />
          <Routes>
            {/* Admin Routes */}
            <Route path="/admincaner01/login" element={<AdminLogin />} />
            <Route path="/admincaner01/*" element={
              <AdminLayout>
                <Routes>
                  <Route index element={<Dashboard />} />
                  <Route path="users" element={<Users />} />
                  <Route path="tours" element={<Tours />} />
                  <Route path="bookings" element={<Bookings />} />
                  <Route path="reviews" element={<Reviews />} />
                  <Route path="settings" element={<Settings />} />
                </Routes>
              </AdminLayout>
            } />

            {/* Public Routes */}
            <Route path="/" element={
              <>
                <Header />
                <main>
                  <Home />
                </main>
                <Footer />
              </>
            } />
            <Route path="/destinations" element={
              <>
                <Header />
                <Destinations />
                <Footer />
              </>
            } />
            <Route path="/experiences" element={
              <>
                <Header />
                <Experiences />
                <Footer />
              </>
            } />
            <Route path="/about" element={
              <>
                <Header />
                <About />
                <Footer />
              </>
            } />
            <Route path="/campaigns" element={
              <>
                <Header />
                <main className="min-h-screen pt-20">
                  <Campaigns />
                </main>
                <Footer />
              </>
            } />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/city/:cityId" element={
              <>
                <Header />
                <CityDetails />
                <Footer />
              </>
            } />
            <Route path="/profile" element={
              <>
                <Header />
                <main className="min-h-screen pt-20">
                  <Profile />
                </main>
                <Footer />
              </>
            } />
            <Route path="/payments" element={
              <>
                <Header />
                <main className="min-h-screen pt-20">
                  <Payments />
                </main>
                <Footer />
              </>
            } />
          </Routes>
        </AuthMiddleware>
      </AdminAuthMiddleware>
    </Router>
  );
}

export default App;