import React, { useState, useEffect } from 'react';
import { Cookie, X, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function CookieConsent() {
  const [isVisible, setIsVisible] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    // Check if user has already accepted cookies
    const hasAccepted = localStorage.getItem('cookieConsent');
    if (!hasAccepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-x-0 bottom-0 z-50">
      {/* Overlay for details panel */}
      {showDetails && (
        <div 
          className="fixed inset-0 bg-black/50 backdrop-blur-sm"
          onClick={() => setShowDetails(false)}
        />
      )}

      {/* Main Cookie Consent */}
      <div className="bg-white border-t border-gray-200 shadow-lg animate-slide-up">
        <div className="container mx-auto px-4 py-4">
          <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
            <div className="flex items-center space-x-4">
              <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center">
                <Cookie className="h-6 w-6 text-blue-600" />
              </div>
              <div className="flex-1">
                <p className="text-gray-600">
                  Size daha iyi bir deneyim sunabilmek için çerezleri kullanıyoruz. 
                  Sitemizi kullanmaya devam ederek, çerez politikamızı kabul etmiş olursunuz.
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <button 
                onClick={() => setShowDetails(true)}
                className="text-sm text-blue-600 hover:text-blue-700 flex items-center"
              >
                Detaylı Bilgi
                <ChevronRight className="h-4 w-4 ml-1" />
              </button>
              <button
                onClick={handleAccept}
                className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                Kabul Et
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Detailed Cookie Information Panel */}
      {showDetails && (
        <div className="fixed bottom-0 right-0 w-full sm:w-96 bg-white shadow-2xl rounded-t-xl animate-slide-up">
          <div className="p-6">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-lg font-semibold text-gray-900">Çerez Ayarları</h3>
              <button 
                onClick={() => setShowDetails(false)}
                className="text-gray-400 hover:text-gray-600"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            <div className="space-y-6">
              <div>
                <label className="flex items-center justify-between">
                  <div>
                    <span className="font-medium text-gray-900">Zorunlu Çerezler</span>
                    <p className="text-sm text-gray-500">
                      Sitenin çalışması için gerekli olan temel çerezler
                    </p>
                  </div>
                  <input type="checkbox" checked disabled className="rounded text-blue-600" />
                </label>
              </div>

              <div>
                <label className="flex items-center justify-between">
                  <div>
                    <span className="font-medium text-gray-900">Performans Çerezleri</span>
                    <p className="text-sm text-gray-500">
                      Site performansını ölçmek için kullanılan çerezler
                    </p>
                  </div>
                  <input type="checkbox" defaultChecked className="rounded text-blue-600" />
                </label>
              </div>

              <div>
                <label className="flex items-center justify-between">
                  <div>
                    <span className="font-medium text-gray-900">İşlevsellik Çerezleri</span>
                    <p className="text-sm text-gray-500">
                      Tercihlerinizi hatırlamak için kullanılan çerezler
                    </p>
                  </div>
                  <input type="checkbox" defaultChecked className="rounded text-blue-600" />
                </label>
              </div>

              <div>
                <label className="flex items-center justify-between">
                  <div>
                    <span className="font-medium text-gray-900">Hedefleme Çerezleri</span>
                    <p className="text-sm text-gray-500">
                      Kişiselleştirilmiş içerik sunmak için kullanılan çerezler
                    </p>
                  </div>
                  <input type="checkbox" defaultChecked className="rounded text-blue-600" />
                </label>
              </div>

              <div className="pt-4 border-t">
                <Link 
                  to="/privacy" 
                  className="text-sm text-blue-600 hover:text-blue-700"
                  onClick={() => setShowDetails(false)}
                >
                  Gizlilik Politikasını Görüntüle
                </Link>
              </div>

              <div className="flex justify-end space-x-4">
                <button
                  onClick={() => setShowDetails(false)}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  İptal
                </button>
                <button
                  onClick={handleAccept}
                  className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  Ayarları Kaydet
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}