import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  User, 
  Settings, 
  LogOut, 
  Heart, 
  Clock, 
  Bell,
  CreditCard
} from 'lucide-react';
import { logout } from '../services/auth';

interface UserProfileMenuProps {
  onClose?: () => void;
}

export default function UserProfileMenu({ onClose }: UserProfileMenuProps) {
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');

  const handleLogout = () => {
    logout();
    if (onClose) onClose();
    navigate('/login');
  };

  return (
    <div className="bg-white rounded-lg shadow-lg border border-gray-100 w-56">
      {/* Profil Başlığı */}
      <div className="p-3 border-b border-gray-100">
        <div className="flex items-center space-x-3">
          <div className="w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center">
            <span className="text-sm font-medium text-blue-600">
              {currentUser.firstName?.[0]}{currentUser.lastName?.[0]}
            </span>
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-900">{currentUser.firstName} {currentUser.lastName}</h3>
            <p className="text-xs text-gray-500">{currentUser.email}</p>
          </div>
        </div>
      </div>

      {/* Menü */}
      <div className="p-2">
        <Link 
          to="/profile" 
          className="flex items-center px-3 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-md"
          onClick={onClose}
        >
          <User className="h-4 w-4 mr-3 text-gray-400" />
          Profilim
        </Link>

        <Link 
          to="/favorites" 
          className="flex items-center px-3 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-md"
          onClick={onClose}
        >
          <Heart className="h-4 w-4 mr-3 text-gray-400" />
          Favorilerim
        </Link>

        <Link 
          to="/bookings" 
          className="flex items-center px-3 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-md"
          onClick={onClose}
        >
          <Clock className="h-4 w-4 mr-3 text-gray-400" />
          Rezervasyonlarım
        </Link>

        <Link 
          to="/payments" 
          className="flex items-center px-3 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-md"
          onClick={onClose}
        >
          <CreditCard className="h-4 w-4 mr-3 text-gray-400" />
          Ödemeler
        </Link>

        <Link 
          to="/notifications" 
          className="flex items-center px-3 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-md"
          onClick={onClose}
        >
          <Bell className="h-4 w-4 mr-3 text-gray-400" />
          Bildirimler
        </Link>

        <Link 
          to="/settings" 
          className="flex items-center px-3 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-md"
          onClick={onClose}
        >
          <Settings className="h-4 w-4 mr-3 text-gray-400" />
          Ayarlar
        </Link>

        <div className="border-t border-gray-100 my-1"></div>

        <button
          onClick={handleLogout}
          className="w-full flex items-center px-3 py-2 text-sm text-red-600 hover:bg-red-50 rounded-md"
        >
          <LogOut className="h-4 w-4 mr-3" />
          Çıkış Yap
        </button>
      </div>
    </div>
  );
}