import React from 'react';
import { 
  Users, 
  Map, 
  Calendar, 
  TrendingUp,
  ArrowUpRight,
  ArrowDownRight,
  Star,
  MessageSquare,
  Bell,
  DollarSign,
  Activity,
  BarChart2,
  AlertTriangle,
  CheckCircle,
  Clock,
  Tag
} from 'lucide-react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import StatsCard from '../../components/admin/StatsCard';
import RecentUsers from '../../components/admin/RecentUsers';
import PopularTours from '../../components/admin/PopularTours';
import RecentReviews from '../../components/admin/RecentReviews';
import NotificationCenter from '../../components/admin/NotificationCenter';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const revenueData = {
  labels: ['Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt', 'Paz'],
  datasets: [
    {
      label: 'Gelir',
      data: [0, 0, 0, 0, 0, 0, 0],
      borderColor: '#10b981',
      backgroundColor: 'rgba(16, 185, 129, 0.1)',
      fill: true,
      tension: 0.4
    },
    {
      label: 'Hedef',
      data: [0, 0, 0, 0, 0, 0, 0],
      borderColor: '#6b7280',
      borderDash: [5, 5],
      fill: false
    }
  ]
};

const bookingData = {
  labels: ['Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt', 'Paz'],
  datasets: [{
    label: 'Rezervasyonlar',
    data: [0, 0, 0, 0, 0, 0, 0],
    backgroundColor: '#6366f1',
    borderRadius: 6
  }]
};

const stats = [
  {
    title: 'Toplam Üye',
    value: '0',
    change: '0%',
    icon: Users,
    color: 'blue'
  },
  {
    title: 'Aktif Turlar',
    value: '0',
    change: '0%',
    icon: Map,
    color: 'green'
  },
  {
    title: 'Bu Ay Rezervasyon',
    value: '0',
    change: '0%',
    icon: Calendar,
    color: 'purple'
  },
  {
    title: 'Toplam Gelir',
    value: '₺0',
    change: '0%',
    icon: TrendingUp,
    color: 'yellow'
  }
];

// Sample data for system health
const systemHealth = {
  cpu: 45,
  memory: 62,
  disk: 38,
  network: 78
};

// Sample data for active campaigns
const activeCampaigns = [
  {
    name: 'Yaz İndirimi',
    discount: '20%',
    endDate: '2024-08-31',
    status: 'active'
  },
  {
    name: 'Erken Rezervasyon',
    discount: '15%',
    endDate: '2024-05-31',
    status: 'active'
  }
];

// Sample data for recent activities
const recentActivities = [
  {
    type: 'booking',
    message: 'Yeni rezervasyon: Kapadokya Turu',
    time: '5 dakika önce',
    user: 'Ahmet Y.'
  },
  {
    type: 'review',
    message: 'Yeni yorum: İstanbul Boğaz Turu',
    time: '15 dakika önce',
    user: 'Mehmet K.'
  },
  {
    type: 'campaign',
    message: 'Yeni kampanya başlatıldı: Yaz İndirimi',
    time: '1 saat önce',
    user: 'Admin'
  }
];

// Sample data for alerts
const systemAlerts = [
  {
    type: 'warning',
    message: 'Disk alanı %80 doluluk oranına ulaştı',
    time: '2 saat önce'
  },
  {
    type: 'success',
    message: 'Otomatik yedekleme başarıyla tamamlandı',
    time: '4 saat önce'
  }
];

export default function Dashboard() {
  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Yönetim Paneli</h1>
          <p className="text-gray-600">Hoş geldiniz, bugünün özeti aşağıdadır.</p>
        </div>
      </div>

      {/* Quick Stats */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {stats.map((stat, index) => (
          <StatsCard
            key={index}
            title={stat.title}
            value={stat.value}
            change={stat.change}
            icon={stat.icon}
            color={stat.color}
          />
        ))}
      </div>

      {/* System Health */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-lg font-semibold mb-4">Sistem Durumu</h2>
          <div className="grid grid-cols-2 gap-4">
            <div className="p-4 bg-gray-50 rounded-lg">
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm font-medium text-gray-600">CPU</span>
                <span className="text-sm text-gray-900">{systemHealth.cpu}%</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div 
                  className="bg-blue-600 h-2 rounded-full" 
                  style={{ width: `${systemHealth.cpu}%` }}
                />
              </div>
            </div>
            <div className="p-4 bg-gray-50 rounded-lg">
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm font-medium text-gray-600">Bellek</span>
                <span className="text-sm text-gray-900">{systemHealth.memory}%</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div 
                  className="bg-green-600 h-2 rounded-full" 
                  style={{ width: `${systemHealth.memory}%` }}
                />
              </div>
            </div>
            <div className="p-4 bg-gray-50 rounded-lg">
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm font-medium text-gray-600">Disk</span>
                <span className="text-sm text-gray-900">{systemHealth.disk}%</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div 
                  className="bg-yellow-600 h-2 rounded-full" 
                  style={{ width: `${systemHealth.disk}%` }}
                />
              </div>
            </div>
            <div className="p-4 bg-gray-50 rounded-lg">
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm font-medium text-gray-600">Ağ</span>
                <span className="text-sm text-gray-900">{systemHealth.network}%</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div 
                  className="bg-purple-600 h-2 rounded-full" 
                  style={{ width: `${systemHealth.network}%` }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Active Campaigns */}
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Aktif Kampanyalar</h2>
            <button className="text-sm text-blue-600 hover:text-blue-700">
              Tümünü Gör
            </button>
          </div>
          <div className="space-y-4">
            {activeCampaigns.map((campaign, index) => (
              <div key={index} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center space-x-4">
                  <div className="bg-blue-100 p-2 rounded-lg">
                    <Tag className="h-5 w-5 text-blue-600" />
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900">{campaign.name}</h3>
                    <p className="text-sm text-gray-500">
                      {campaign.discount} indirim • Bitiş: {new Date(campaign.endDate).toLocaleDateString('tr-TR')}
                    </p>
                  </div>
                </div>
                <span className="px-2 py-1 text-xs font-medium text-green-700 bg-green-100 rounded-full">
                  Aktif
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Recent Activities and Alerts */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-8">
        <div className="lg:col-span-2 bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-lg font-semibold mb-4">Son Aktiviteler</h2>
          <div className="space-y-4">
            {recentActivities.map((activity, index) => (
              <div key={index} className="flex items-start space-x-4 p-4 bg-gray-50 rounded-lg">
                <div className={`p-2 rounded-lg ${
                  activity.type === 'booking' ? 'bg-blue-100 text-blue-600' :
                  activity.type === 'review' ? 'bg-yellow-100 text-yellow-600' :
                  'bg-green-100 text-green-600'
                }`}>
                  {activity.type === 'booking' ? <Calendar className="h-5 w-5" /> :
                   activity.type === 'review' ? <Star className="h-5 w-5" /> :
                   <Tag className="h-5 w-5" />}
                </div>
                <div className="flex-1">
                  <p className="text-sm font-medium text-gray-900">{activity.message}</p>
                  <div className="flex items-center mt-1 text-sm text-gray-500">
                    <Clock className="h-4 w-4 mr-1" />
                    {activity.time}
                    <span className="mx-2">•</span>
                    <span>{activity.user}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-lg font-semibold mb-4">Sistem Uyarıları</h2>
          <div className="space-y-4">
            {systemAlerts.map((alert, index) => (
              <div key={index} className={`p-4 rounded-lg ${
                alert.type === 'warning' ? 'bg-yellow-50' : 'bg-green-50'
              }`}>
                <div className="flex items-center">
                  {alert.type === 'warning' ? (
                    <AlertTriangle className="h-5 w-5 text-yellow-500 mr-2" />
                  ) : (
                    <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
                  )}
                  <div className="flex-1">
                    <p className={`text-sm font-medium ${
                      alert.type === 'warning' ? 'text-yellow-700' : 'text-green-700'
                    }`}>
                      {alert.message}
                    </p>
                    <p className="text-xs text-gray-500 mt-1">{alert.time}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Charts and Stats */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
        {/* Revenue Chart */}
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Gelir Grafiği</h2>
            <select className="text-sm border-gray-300 rounded-md">
              <option>Son 7 gün</option>
              <option>Son 30 gün</option>
              <option>Son 3 ay</option>
            </select>
          </div>
          <div className="h-64">
            <Line
              data={revenueData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                    grid: {
                      drawBorder: false
                    }
                  }
                }
              }}
            />
          </div>
        </div>

        {/* Booking Stats */}
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Rezervasyon İstatistikleri</h2>
            <select className="text-sm border-gray-300 rounded-md">
              <option>Günlük</option>
              <option>Haftalık</option>
              <option>Aylık</option>
            </select>
          </div>
          <div className="h-64">
            <Bar
              data={bookingData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true
                  }
                }
              }}
            />
          </div>
        </div>
      </div>

      {/* Recent Data */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2 bg-white rounded-lg shadow-sm p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Son Kayıt Olan Üyeler</h2>
            <button className="text-blue-600 hover:text-blue-700 text-sm font-medium">
              Tümünü Gör
            </button>
          </div>
          <RecentUsers />
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Bildirimler</h2>
            <button className="text-blue-600 hover:text-blue-700 text-sm font-medium">
              Tümünü Gör
            </button>
          </div>
          <NotificationCenter />
        </div>
      </div>
    </div>
  );
}