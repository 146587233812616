import React from 'react';
import { Tag, Calendar, Clock, MapPin, Users, ArrowRight, Percent } from 'lucide-react';

const campaigns = [
  {
    id: 1,
    title: 'Nisan Ayına Özel %20 İndirim',
    description: 'Tüm Kapadokya turlarında geçerli erken rezervasyon indirimi',
    image: 'https://images.unsplash.com/photo-1641128324972-af3212f0f6bd',
    discount: 20,
    validUntil: '2024-04-30',
    tours: [
      {
        name: 'Kapadokya Balon Turu',
        location: 'Nevşehir',
        duration: '3 Gün',
        price: 4500,
        discountedPrice: 3600
      }
    ]
  },
  {
    id: 2,
    title: 'Erken Rezervasyon Fırsatı',
    description: 'Yaz dönemi İstanbul turlarında %15 indirim',
    image: 'https://images.unsplash.com/photo-1527838832700-5059252407fa',
    discount: 15,
    validUntil: '2024-05-31',
    tours: [
      {
        name: 'İstanbul Boğaz Turu',
        location: 'İstanbul',
        duration: '1 Gün',
        price: 1200,
        discountedPrice: 1020
      }
    ]
  },
  {
    id: 3,
    title: 'Bahar Kampanyası',
    description: 'Ege turlarında 2. kişiye %50 indirim',
    image: 'https://images.unsplash.com/photo-1590595978583-3967cf17d2ea',
    discount: 50,
    validUntil: '2024-05-15',
    tours: [
      {
        name: 'Efes Antik Kenti Turu',
        location: 'İzmir',
        duration: '2 Gün',
        price: 2800,
        discountedPrice: 2100
      }
    ]
  }
];

export default function Campaigns() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50">
      {/* Hero Section */}
      <div className="relative h-[40vh] overflow-hidden">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: 'url(https://images.unsplash.com/photo-1524231757912-21f4fe3a7200)',
            backgroundAttachment: 'fixed'
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/40 to-black/70" />
        </div>
        
        <div className="relative container mx-auto px-4 h-full flex flex-col justify-center">
          <div className="max-w-3xl">
            <div className="inline-flex items-center space-x-2 px-4 py-2 bg-white/10 backdrop-blur-sm rounded-full mb-6">
              <Percent className="h-5 w-5 text-yellow-400" />
              <span className="text-white/90">Özel Fırsatlar ve İndirimler</span>
            </div>

            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Güncel Kampanyalar
            </h1>
            <p className="text-xl text-white/90">
              Kaçırılmayacak fırsatlarla unutulmaz deneyimler yaşayın
            </p>
          </div>
        </div>
      </div>

      {/* Campaigns Grid */}
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {campaigns.map((campaign) => (
            <div
              key={campaign.id}
              className="bg-white rounded-xl shadow-sm hover:shadow-lg transition-all duration-300 overflow-hidden group"
            >
              <div className="relative aspect-[4/3]">
                <img
                  src={campaign.image}
                  alt={campaign.title}
                  className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-500"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent" />
                
                {/* Discount Badge */}
                <div className="absolute top-4 right-4">
                  <div className="bg-red-500 text-white px-3 py-1 rounded-full text-sm font-bold">
                    %{campaign.discount} İndirim
                  </div>
                </div>

                <div className="absolute bottom-4 left-4 right-4">
                  <h3 className="text-xl font-bold text-white mb-2">{campaign.title}</h3>
                  <p className="text-white/90 text-sm">{campaign.description}</p>
                </div>
              </div>

              <div className="p-6">
                {/* Validity Period */}
                <div className="flex items-center text-gray-600 mb-4">
                  <Calendar className="h-5 w-5 mr-2 text-blue-600" />
                  <span>Son Geçerlilik: {new Date(campaign.validUntil).toLocaleDateString('tr-TR')}</span>
                </div>

                {/* Tour Details */}
                {campaign.tours.map((tour, index) => (
                  <div key={index} className="border-t border-gray-100 pt-4">
                    <h4 className="font-semibold text-gray-900 mb-2">{tour.name}</h4>
                    
                    <div className="grid grid-cols-2 gap-2 mb-4">
                      <div className="flex items-center text-gray-600">
                        <MapPin className="h-4 w-4 mr-1 text-gray-400" />
                        <span className="text-sm">{tour.location}</span>
                      </div>
                      <div className="flex items-center text-gray-600">
                        <Clock className="h-4 w-4 mr-1 text-gray-400" />
                        <span className="text-sm">{tour.duration}</span>
                      </div>
                    </div>

                    <div className="flex items-center justify-between">
                      <div>
                        <span className="text-sm text-gray-500 line-through">
                          {tour.price}₺
                        </span>
                        <span className="ml-2 text-lg font-bold text-red-500">
                          {tour.discountedPrice}₺
                        </span>
                      </div>
                      <button className="flex items-center text-blue-600 hover:text-blue-700">
                        İncele
                        <ArrowRight className="h-4 w-4 ml-1" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}