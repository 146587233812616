import React from 'react';
import { 
  Landmark, 
  Mountain, 
  Utensils, 
  Music, 
  Camera, 
  Sun, 
  Castle,
  Coffee,
  Tent,
  Ship,
  Building2,
  Wine,
  Trees,
  Palmtree,
  Heart,
  Gem,
  Brush,
  Shell,
  Bird,
  Fish,
  Flower2,
  Leaf,
  Warehouse,
  UtensilsCrossed
} from 'lucide-react';

const features = [
  {
    icon: Landmark,
    title: 'Tarihi Yapılar',
    description: 'Antik kentler, camiler, kiliseler, hanlar ve kervansaraylar',
    color: 'blue'
  },
  {
    icon: Mountain,
    title: 'Dağlar ve Yaylalar',
    description: 'Kaçkar Dağları, Toroslar ve yemyeşil yaylalar',
    color: 'green'
  },
  {
    icon: Shell,
    title: 'Plajlar ve Koylar',
    description: 'Turkuaz sulara sahip eşsiz plajlar ve gizli koylar',
    color: 'cyan'
  },
  {
    icon: Flower2,
    title: 'Doğal Güzellikler',
    description: 'Peri bacaları, travertenler, kanyonlar ve mağaralar',
    color: 'emerald'
  },
  {
    icon: UtensilsCrossed,
    title: 'Mutfak Kültürü',
    description: 'UNESCO tescilli geleneksel Türk mutfağı',
    color: 'orange'
  },
  {
    icon: Heart,
    title: 'El Sanatları',
    description: 'Halılar, çiniler, seramikler ve geleneksel el işleri',
    color: 'rose'
  },
  {
    icon: Music,
    title: 'Festivaller',
    description: 'Yöresel festivaller, şenlikler ve kutlamalar',
    color: 'purple'
  },
  {
    icon: Castle,
    title: 'Kaleler',
    description: 'Antik kaleler, surlar ve tarihi savunma yapıları',
    color: 'gray'
  },
  {
    icon: Coffee,
    title: 'Kahve Kültürü',
    description: 'UNESCO listesindeki Türk kahvesi geleneği',
    color: 'amber'
  },
  {
    icon: Bird,
    title: 'Milli Parklar',
    description: 'Kuş cennetleri ve doğal yaşam alanları',
    color: 'lime'
  },
  {
    icon: Building2,
    title: 'Müzeler',
    description: 'Arkeoloji, etnografya ve modern sanat müzeleri',
    color: 'indigo'
  },
  {
    icon: Wine,
    title: 'Bağlar',
    description: 'Antik çağlardan gelen şarap kültürü ve bağ rotaları',
    color: 'purple'
  },
  {
    icon: Fish,
    title: 'Su Altı Zenginliği',
    description: 'Antik batıklar ve zengin deniz yaşamı',
    color: 'blue'
  },
  {
    icon: Brush,
    title: 'Sanat',
    description: 'Geleneksel ve modern Türk sanatları',
    color: 'pink'
  },
  {
    icon: Warehouse,
    title: 'Çarşılar',
    description: 'Tarihi kapalı çarşılar ve antik pazarlar',
    color: 'yellow'
  },
  {
    icon: Leaf,
    title: 'Endemik Türler',
    description: 'Türkiye\'ye özgü bitki ve hayvan türleri',
    color: 'green'
  }
];

const colorClasses = {
  blue: 'bg-blue-50 text-blue-600',
  green: 'bg-green-50 text-green-600',
  yellow: 'bg-yellow-50 text-yellow-600',
  purple: 'bg-purple-50 text-purple-600',
  pink: 'bg-pink-50 text-pink-600',
  orange: 'bg-orange-50 text-orange-600',
  gray: 'bg-gray-50 text-gray-600',
  amber: 'bg-amber-50 text-amber-600',
  emerald: 'bg-emerald-50 text-emerald-600',
  cyan: 'bg-cyan-50 text-cyan-600',
  indigo: 'bg-indigo-50 text-indigo-600',
  rose: 'bg-rose-50 text-rose-600',
  lime: 'bg-lime-50 text-lime-600'
};

export default function RichFeatures() {
  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900">Türkiye'nin Zenginlikleri</h2>
          <p className="mt-4 text-lg text-gray-600">
            Binlerce yıllık tarihi, eşsiz doğası ve zengin kültürüyle keşfedilmeyi bekleyen hazineler
          </p>
          <p className="mt-2 text-gray-500">
            UNESCO Dünya Mirası Listesi'nde 19 kültürel ve doğal varlık
          </p>
        </div>
        
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {features.map((feature, index) => (
            <div
              key={index}
              className={`${colorClasses[feature.color as keyof typeof colorClasses]} p-6 rounded-xl transition-all duration-300 hover:scale-105 hover:shadow-lg`}
            >
              <feature.icon className="h-8 w-8 mb-4" />
              <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
              <p className="text-sm opacity-75">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}