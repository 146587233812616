import React from 'react';
import { Calendar, MapPin, Camera, Users } from 'lucide-react';

const inspirations = [
  {
    title: 'Kapadokya\'da Gün Doğumu',
    image: 'https://images.unsplash.com/photo-1641128324972-af3212f0f6bd',
    location: 'Nevşehir',
    season: 'İlkbahar',
    type: 'Fotoğraf',
    difficulty: 'Kolay'
  },
  {
    title: 'Likya Yolu\'nda Yürüyüş',
    image: 'https://images.unsplash.com/photo-1551632811-561732d1e306',
    location: 'Antalya',
    season: 'Sonbahar',
    type: 'Doğa',
    difficulty: 'Orta'
  },
  {
    title: 'İstanbul Boğazı\'nda Gün Batımı',
    image: 'https://images.unsplash.com/photo-1527838832700-5059252407fa',
    location: 'İstanbul',
    season: 'Yaz',
    type: 'Şehir',
    difficulty: 'Kolay'
  }
];

export default function InspirationSection() {
  return (
    <section className="py-24 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            İlham Verici Deneyimler
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Türkiye'nin dört bir yanından unutulmaz anlar ve özel deneyimler
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {inspirations.map((item, index) => (
            <div key={index} className="group cursor-pointer">
              <div className="relative aspect-[4/3] rounded-xl overflow-hidden mb-6">
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                
                <div className="absolute bottom-4 left-4 right-4">
                  <h3 className="text-xl font-bold text-white mb-2">{item.title}</h3>
                  <div className="flex items-center text-white/90">
                    <MapPin className="h-4 w-4 mr-1" />
                    <span className="text-sm">{item.location}</span>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center space-x-2 text-gray-600">
                  <Calendar className="h-4 w-4" />
                  <span className="text-sm">{item.season}</span>
                </div>
                <div className="flex items-center space-x-2 text-gray-600">
                  <Camera className="h-4 w-4" />
                  <span className="text-sm">{item.type}</span>
                </div>
                <div className="flex items-center space-x-2 text-gray-600">
                  <Users className="h-4 w-4" />
                  <span className="text-sm">{item.difficulty}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}