import React from 'react';
import { Shield, Award, Clock, HeartHandshake } from 'lucide-react';

const features = [
  {
    icon: Shield,
    title: 'Güvenli Ödeme',
    description: '256-bit SSL şifreleme ile güvenli ödeme işlemleri'
  },
  {
    icon: Award,
    title: 'En İyi Fiyat Garantisi',
    description: 'Piyasadaki en rekabetçi fiyatları sunuyoruz'
  },
  {
    icon: Clock,
    title: '7/24 Destek',
    description: 'Seyahatiniz boyunca kesintisiz müşteri desteği'
  },
  {
    icon: HeartHandshake,
    title: 'Deneyimli Rehberler',
    description: 'Profesyonel ve uzman rehberler eşliğinde turlar'
  }
];

export default function WhyChooseUs() {
  return (
    <section className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900">Neden Bizi Seçmelisiniz?</h2>
          <p className="mt-4 text-lg text-gray-600">
            20 yıllık deneyimimiz ve binlerce mutlu müşterimizle fark yaratıyoruz
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-shadow"
            >
              <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4">
                <feature.icon className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                {feature.title}
              </h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}