import React from 'react';
import { Mountain, Sunrise, Shell, Coffee, Camera, Map, Heart, Navigation, TreePine, Castle } from 'lucide-react';

const categories = [
  {
    icon: Mountain,
    title: 'Dağlar ve Yaylalar',
    description: 'Kaçkar\'dan Toroslar\'a uzanan muhteşem zirveleri keşfedin',
    image: 'https://images.unsplash.com/photo-1589182337358-2cb63099350c'
  },
  {
    icon: Shell,
    title: 'Plajlar ve Koylar',
    description: 'Turkuaz sulara sahip eşsiz plajlar ve gizli koylar',
    image: 'https://images.unsplash.com/photo-1558386619-d56527c1a1c3'
  },
  {
    icon: Castle,
    title: 'Tarihi Yerler',
    description: 'Antik kentlerden Osmanlı eserlerine tarihi yolculuk',
    image: 'https://images.unsplash.com/photo-1589030347209-71cc45e4f58e'
  },
  {
    icon: TreePine,
    title: 'Milli Parklar',
    description: 'Doğal yaşamın korunduğu eşsiz parkları ziyaret edin',
    image: 'https://images.unsplash.com/photo-1565071559227-20ab25b7685e'
  }
];

export default function ExploreSection() {
  return (
    <section className="py-24 bg-gradient-to-b from-gray-50 to-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Türkiye'yi Keşfedin
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Her zevke ve ilgi alanına uygun rotalar, öneriler ve detaylı rehberler
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {categories.map((category, index) => (
            <div
              key={index}
              className="group relative h-96 rounded-2xl overflow-hidden cursor-pointer"
            >
              <img
                src={category.image}
                alt={category.title}
                className="absolute inset-0 w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent" />
              
              <div className="absolute inset-0 p-8 flex flex-col justify-end">
                <div className="flex items-center space-x-3 mb-4">
                  <div className="p-3 rounded-xl bg-white/10 backdrop-blur-sm">
                    <category.icon className="h-6 w-6 text-white" />
                  </div>
                  <h3 className="text-2xl font-bold text-white">{category.title}</h3>
                </div>
                <p className="text-white/90 mb-6">{category.description}</p>
                <button className="self-start px-6 py-3 bg-white/10 backdrop-blur-sm hover:bg-white/20 rounded-xl text-white transition-all">
                  Keşfet
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}