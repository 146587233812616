import React, { useState } from 'react';
import { 
  MapPin, Mail, Phone, Calendar, Star, Heart, Clock, Settings, Shield, 
  CreditCard, Bell, Camera, Edit2, Save, X, Download, Upload, User,
  FileText, MessageSquare, Bookmark, Map, Gift, Award, Zap, Share2,
  Lock, Key, Smartphone, Globe, AlertTriangle, CheckCircle, ChevronRight, Trash2
} from 'lucide-react';
import { useProfile } from '../hooks/useProfile';

export default function Profile() {
  const {
    isEditing,
    setIsEditing,
    profile,
    profileImage,
    formData,
    setFormData,
    country,
    setCountry,
    successMessage,
    errorMessage,
    handleImageSelect,
    handleImageRemove,
    handleSubmit
  } = useProfile();

  const [activeTab, setActiveTab] = useState('profile');

  if (!profile) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600 mx-auto"></div>
          <p className="mt-4 text-gray-600">Yükleniyor...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-50 via-white to-pink-50">
      {/* Header */}
      <div className="bg-gradient-to-r from-purple-600 to-pink-500 text-white py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h1 className="text-3xl font-bold">Profil Ayarları</h1>
          <p className="mt-2 text-purple-100">Hesap bilgilerinizi yönetin</p>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 -mt-8">
        {/* Success/Error Messages */}
        {successMessage && (
          <div className="mb-6 bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded">
            <div className="flex">
              <CheckCircle className="h-5 w-5 mr-2" />
              {successMessage}
            </div>
          </div>
        )}

        {errorMessage && (
          <div className="mb-6 bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded">
            <div className="flex">
              <AlertTriangle className="h-5 w-5 mr-2" />
              {errorMessage}
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
          {/* Left Sidebar */}
          <div className="lg:col-span-1 space-y-6">
            {/* Profile Card */}
            <div className="bg-white rounded-xl shadow-sm overflow-hidden">
              <div className="bg-gradient-to-r from-purple-600 to-pink-500 p-6 text-center">
                <div className="relative inline-block">
                  {profileImage ? (
                    <img
                      src={profileImage}
                      alt="Profil"
                      className="w-24 h-24 rounded-full border-4 border-white"
                    />
                  ) : (
                    <div className="w-24 h-24 rounded-full bg-white flex items-center justify-center text-3xl font-bold text-purple-600 border-4 border-white">
                      {profile.firstName[0]}{profile.lastName[0]}
                    </div>
                  )}
                  <button 
                    onClick={() => document.getElementById('profile-image')?.click()}
                    className="absolute bottom-0 right-0 bg-white rounded-full p-2 shadow-lg hover:bg-gray-50"
                  >
                    <Camera className="h-4 w-4 text-gray-600" />
                  </button>
                  <input
                    id="profile-image"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={(e) => {
                      const file = e.target.files?.[0];
                      if (file) handleImageSelect(file);
                    }}
                  />
                </div>
                <h2 className="mt-4 text-xl font-bold text-white">
                  {profile.firstName} {profile.lastName}
                </h2>
                <p className="text-purple-100">Premium Üye</p>
              </div>

              {/* Menu */}
              <div className="p-4">
                <nav className="space-y-2">
                  <button
                    onClick={() => setActiveTab('profile')}
                    className={`w-full flex items-center px-4 py-2 text-sm rounded-lg ${
                      activeTab === 'profile'
                        ? 'bg-purple-50 text-purple-600'
                        : 'text-gray-600 hover:bg-gray-50'
                    }`}
                  >
                    <User className="h-4 w-4 mr-3" />
                    Profil Bilgileri
                  </button>

                  <button
                    onClick={() => setActiveTab('security')}
                    className={`w-full flex items-center px-4 py-2 text-sm rounded-lg ${
                      activeTab === 'security'
                        ? 'bg-purple-50 text-purple-600'
                        : 'text-gray-600 hover:bg-gray-50'
                    }`}
                  >
                    <Shield className="h-4 w-4 mr-3" />
                    Güvenlik
                  </button>

                  <button
                    onClick={() => setActiveTab('settings')}
                    className={`w-full flex items-center px-4 py-2 text-sm rounded-lg ${
                      activeTab === 'settings'
                        ? 'bg-purple-50 text-purple-600'
                        : 'text-gray-600 hover:bg-gray-50'
                    }`}
                  >
                    <Settings className="h-4 w-4 mr-3" />
                    Ayarlar
                  </button>
                </nav>
              </div>
            </div>

            {/* Achievements */}
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h3 className="text-lg font-semibold mb-4">Başarılar</h3>
              <div className="grid grid-cols-3 gap-4">
                <div className="text-center">
                  <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-2">
                    <Award className="h-6 w-6 text-purple-600" />
                  </div>
                  <span className="text-xs text-gray-600">Gezgin</span>
                </div>
                <div className="text-center">
                  <div className="w-12 h-12 bg-pink-100 rounded-full flex items-center justify-center mx-auto mb-2">
                    <Star className="h-6 w-6 text-pink-600" />
                  </div>
                  <span className="text-xs text-gray-600">Yorumcu</span>
                </div>
                <div className="text-center">
                  <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-2">
                    <Map className="h-6 w-6 text-purple-600" />
                  </div>
                  <span className="text-xs text-gray-600">Kaşif</span>
                </div>
              </div>
            </div>
          </div>

          {/* Ana İçerik */}
          <div className="lg:col-span-3">
            {/* Profil Bilgileri */}
            <div className="bg-white rounded-xl shadow-sm p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold">Profil Bilgileri</h2>
                <button
                  onClick={() => setIsEditing(!isEditing)}
                  className="flex items-center text-purple-600 hover:text-purple-700"
                >
                  {isEditing ? (
                    <>
                      <X className="h-5 w-5 mr-1" />
                      İptal
                    </>
                  ) : (
                    <>
                      <Edit2 className="h-5 w-5 mr-1" />
                      Düzenle
                    </>
                  )}
                </button>
              </div>

              {isEditing ? (
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Ad</label>
                      <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                        className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-purple-500 focus:ring focus:ring-purple-200"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Soyad</label>
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                        className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-purple-500 focus:ring focus:ring-purple-200"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">E-posta</label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-purple-500 focus:ring focus:ring-purple-200"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Telefon</label>
                      <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                        className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-purple-500 focus:ring focus:ring-purple-200"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Şehir</label>
                      <input
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                        className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-purple-500 focus:ring focus:ring-purple-200"
                      />
                    </div>
                  </div>

                  <div className="flex justify-end space-x-3">
                    <button
                      type="button"
                      onClick={() => setIsEditing(false)}
                      className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
                    >
                      İptal
                    </button>
                    <button
                      type="submit"
                      className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
                    >
                      <Save className="h-5 w-5 mr-2 inline-block" />
                      Kaydet
                    </button>
                  </div>
                </form>
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="text-sm text-gray-500">Ad</label>
                    <p className="text-gray-900">{profile.firstName}</p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-500">Soyad</label>
                    <p className="text-gray-900">{profile.lastName}</p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-500">E-posta</label>
                    <p className="text-gray-900">{profile.email}</p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-500">Telefon</label>
                    <p className="text-gray-900">{profile.phone}</p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-500">Şehir</label>
                    <p className="text-gray-900">{profile.city}</p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-500">Üyelik Tarihi</label>
                    <p className="text-gray-900">{new Date(profile.joinDate).toLocaleDateString('tr-TR')}</p>
                  </div>
                </div>
              )}
            </div>

            {/* Hızlı İstatistikler */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
              <div className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-gray-500">Toplam Tur</p>
                    <p className="text-2xl font-bold text-gray-900">24</p>
                  </div>
                  <div className="bg-purple-100 p-3 rounded-lg">
                    <Map className="h-6 w-6 text-purple-600" />
                  </div>
                </div>
              </div>
              <div className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-gray-500">Toplam Yorum</p>
                    <p className="text-2xl font-bold text-gray-900">156</p>
                  </div>
                  <div className="bg-pink-100 p-3 rounded-lg">
                    <MessageSquare className="h-6 w-6 text-pink-600" />
                  </div>
                </div>
              </div>
              <div className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-gray-500">Puan Ortalaması</p>
                    <p className="text-2xl font-bold text-gray-900">4.9</p>
                  </div>
                  <div className="bg-yellow-100 p-3 rounded-lg">
                    <Star className="h-6 w-6 text-yellow-600" />
                  </div>
                </div>
              </div>
            </div>

            {/* Son Aktiviteler */}
            <div className="bg-white rounded-xl shadow-sm p-6 mt-6">
              <h3 className="text-lg font-semibold mb-4">Son Aktiviteler</h3>
              <div className="space-y-4">
                <div className="flex items-start space-x-4">
                  <div className="bg-green-100 p-2 rounded-lg">
                    <Bookmark className="h-5 w-5 text-green-600" />
                  </div>
                  <div>
                    <p className="text-gray-900">Kapadokya Turu rezervasyonu yapıldı</p>
                    <p className="text-sm text-gray-500">2 saat önce</p>
                  </div>
                </div>
                <div className="flex items-start space-x-4">
                  <div className="bg-yellow-100 p-2 rounded-lg">
                    <Star className="h-5 w-5 text-yellow-600" />
                  </div>
                  <div>
                    <p className="text-gray-900">İstanbul Boğaz Turu'na yorum yapıldı</p>
                    <p className="text-sm text-gray-500">1 gün önce</p>
                  </div>
                </div>
                <div className="flex items-start space-x-4">
                  <div className="bg-red-100 p-2 rounded-lg">
                    <Heart className="h-5 w-5 text-red-600" />
                  </div>
                  <div>
                    <p className="text-gray-900">Pamukkale Turu favorilere eklendi</p>
                    <p className="text-sm text-gray-500">2 gün önce</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}