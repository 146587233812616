import { CityData } from './types';

const citiesData: Record<string, CityData> = {
  adana: {
    id: 'adana',
    name: 'Adana',
    region: 'Akdeniz',
    population: 2258718,
    slogan: 'Akdeniz\'in Lezzet ve Kültür Başkenti',
    description: 'Akdeniz\'in bereketli topraklarında kurulu olan Adana, tarihi İpek Yolu üzerindeki konumu, zengin mutfağı ve kültürel mirasıyla öne çıkar. Kebabı, pamuk tarlaları, Seyhan Nehri ve tarihi yapılarıyla tanınan şehir, modern yaşamla geleneksel kültürü harmanlayan özgün bir atmosfere sahiptir. Toros Dağları\'nın eteklerinde yer alan kent, antik dönemden günümüze birçok medeniyete ev sahipliği yapmış, her birinden izler taşıyan zengin bir tarih ve kültür hazinesidir.',
    coverImage: 'https://images.unsplash.com/photo-1589030347209-71cc45e4f58e',
    highlights: [
      'Dünyaca ünlü Adana Kebabı',
      'Taşköprü - Roma dönemi köprüsü',
      'Varda Köprüsü\'nün muhteşem manzarası',
      'Seyhan Nehri kıyısında gezinti',
      'Büyük Saat Kulesi',
      'Sabancı Merkez Camii',
      'Kapıkaya Kanyonu\'nun doğal güzelliği',
      'Yumurtalık sahilleri',
      'Misis Antik Kenti',
      'Ramazanoğlu Konağı tarihi',
      'Adana Arkeoloji Müzesi',
      'Geleneksel Şalgam Suyu'
    ],
    bestSeason: 'İlkbahar (Mart-Mayıs) ve Sonbahar (Eylül-Kasım)',
    popularPlaces: [
      {
        name: 'Taşköprü',
        description: 'Roma İmparatoru Hadrianus döneminde yapılan, Seyhan Nehri üzerindeki tarihi köprü, şehrin en eski yapılarından biridir. 310 metre uzunluğundaki köprü, 2000 yılı aşkın tarihiyle ayakta durmaktadır.',
        image: 'https://images.unsplash.com/photo-1595763458496-ed9d10c0a1b7'
      },
      {
        name: 'Varda Köprüsü',
        description: 'Alman mühendisler tarafından inşa edilen bu görkemli demiryolu köprüsü, 172 metre uzunluğu ve 98 metre yüksekliğiyle nefes kesici manzaralar sunar. James Bond filminde de kullanılan köprü, fotoğraf tutkunlarının vazgeçilmez durağıdır.',
        image: 'https://images.unsplash.com/photo-1598258710957-456e3c91efb2'
      },
      {
        name: 'Sabancı Merkez Camii',
        description: 'Türkiye\'nin en büyük camisinden biri olan yapı, 6 minaresi ve 28.500 kişilik kapasitesiyle etkileyici bir mimari örneğidir. Seyhan Nehri kıyısındaki konumuyla şehrin simgelerinden biridir.',
        image: 'https://images.unsplash.com/photo-1603490665722-c15c4a3d7a83'
      },
      {
        name: 'Kapıkaya Kanyonu',
        description: 'Toros Dağları\'nın eteklerinde yer alan kanyon, doğa yürüyüşü ve fotoğrafçılık için ideal bir lokasyondur. Berrak suları ve etkileyici kaya oluşumlarıyla doğa tutkunlarını bekliyor.',
        image: 'https://images.unsplash.com/photo-1598184753257-46b91f4a3f21'
      },
      {
        name: 'Büyük Saat Kulesi',
        description: '1882 yılında inşa edilen kule, şehrin merkezinde yer alan önemli bir tarihi yapıdır. 32 metre yüksekliğindeki kule, Adana\'nın en eski sembollerinden biridir.',
        image: 'https://images.unsplash.com/photo-1597586914615-c597d8665a38'
      },
      {
        name: 'Misis Antik Kenti',
        description: 'Yaklaşık 7000 yıllık tarihe sahip antik kent, Roma ve Bizans dönemlerine ait kalıntılarıyla tarih meraklılarını cezbediyor. Mozaik müzesi özellikle görülmeye değerdir.',
        image: 'https://images.unsplash.com/photo-1596435163709-b374bc00b7dc'
      }
    ],
    tours: [
      {
        name: 'Adana Lezzet Turu',
        duration: 'Yarım Gün (4 Saat)',
        price: 600
      },
      {
        name: 'Tarihi Adana Turu',
        duration: 'Tam Gün (8 Saat)',
        price: 900
      },
      {
        name: 'Varda Köprüsü & Kapıkaya Kanyonu Turu',
        duration: 'Tam Gün (8 Saat)',
        price: 1100
      },
      {
        name: 'Yumurtalık Plaj Turu',
        duration: 'Tam Gün (8 Saat)',
        price: 750
      },
      {
        name: 'Akşam Seyhan Nehri Turu',
        duration: '3 Saat',
        price: 400
      },
      {
        name: 'Antik Kentler Turu',
        duration: 'Tam Gün (9 Saat)',
        price: 950
      }
    ]
  },
  istanbul: {
    id: 'istanbul',
    name: 'İstanbul',
    region: 'Marmara',
    population: 15840900,
    slogan: 'İki Kıtanın Buluştuğu Şehir',
    description: 'Asya ve Avrupa kıtalarını birleştiren İstanbul, 8500 yıllık tarihi, zengin kültürel mirası ve modern yaşamı mükemmel bir uyumla bir ararda sunar.',
    coverImage: 'https://images.unsplash.com/photo-1524231757912-21f4fe3a7200',
    highlights: [
      'Ayasofya-i Kebir Camii',
      'Topkapı Sarayı',
      'Sultanahmet Camii',
      'Kapalıçarşı',
      'Boğaz Turu',
      'Galata Kulesi',
      'Dolmabahçe Sarayı',
      'Yerebatan Sarnıcı',
      'Süleymaniye Camii',
      'Kız Kulesi'
    ],
    bestSeason: 'İlkbahar (Nisan-Mayıs) ve Sonbahar (Eylül-Ekim)',
    popularPlaces: [
      {
        name: 'Ayasofya-i Kebir Camii',
        description: '1500 yıllık tarihi ile dünya mimarlık tarihinin başyapıtlarından biri.',
        image: 'https://images.unsplash.com/photo-1545459720-aac8509eb02c'
      },
      {
        name: 'Topkapı Sarayı',
        description: 'Osmanlı İmparatorluğu\'nun 400 yıl boyunca yönetim merkezi.',
        image: 'https://images.unsplash.com/photo-1578912996078-305d92249cea'
      },
      {
        name: 'Sultanahmet Camii',
        description: '17. yüzyılda inşa edilen ve 20.000\'den fazla mavi İznik çinisi ile süslü.',
        image: 'https://images.unsplash.com/photo-1541432901042-2d8bd64b4a9b'
      }
    ],
    tours: [
      {
        name: 'Tarihi Yarımada Turu',
        duration: 'Tam Gün (8 Saat)',
        price: 1200
      },
      {
        name: 'Boğaz Turu',
        duration: 'Yarım Gün (4 Saat)',
        price: 800
      },
      {
        name: 'Adalar Turu',
        duration: 'Tam Gün (8 Saat)',
        price: 950
      }
    ]
  }
};

export const getCityData = (cityId: string): CityData | undefined => {
  return citiesData[cityId.toLowerCase()];
};

export type { CityData };
export { citiesData };