import React from 'react';
import { Users, Map, Star, Award } from 'lucide-react';

const stats = [
  {
    icon: Users,
    value: '50,000+',
    label: 'Mutlu Müşteri'
  },
  {
    icon: Map,
    value: '100+',
    label: 'Benzersiz Rota'
  },
  {
    icon: Star,
    value: '4.9/5',
    label: 'Müşteri Memnuniyeti'
  },
  {
    icon: Award,
    value: '15+',
    label: 'Yıllık Deneyim'
  }
];

export default function Statistics() {
  return (
    <section className="py-16 bg-blue-600">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {stats.map((stat, index) => (
            <div key={index} className="text-center">
              <div className="inline-flex items-center justify-center w-16 h-16 bg-white/10 rounded-full mb-4">
                <stat.icon className="h-8 w-8 text-white" />
              </div>
              <div className="text-3xl font-bold text-white mb-2">{stat.value}</div>
              <div className="text-blue-100">{stat.label}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}