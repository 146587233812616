import React from 'react';
import HeroSection from '../components/home/HeroSection';
import GuideCategories from '../components/home/GuideCategories';
import PopularDestinations from '../components/home/PopularDestinations';
import SeasonalGuide from '../components/home/SeasonalGuide';
import TravelTips from '../components/home/TravelTips';
import NewsletterSignup from '../components/NewsletterSignup';
import ExploreSection from '../components/home/ExploreSection';
import LocalExperiences from '../components/home/LocalExperiences';
import InspirationSection from '../components/home/InspirationSection';
import RichFeatures from '../components/RichFeatures';
import RegionalHighlights from '../components/RegionalHighlights';
import WhyChooseUs from '../components/WhyChooseUs';
import Statistics from '../components/Statistics';
import Testimonials from '../components/Testimonials';

export default function Home() {
  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <HeroSection />

      {/* Quick Categories */}
      <div className="py-16">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Keşfetmeye Başlayın
            </h2>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">
              Türkiye'nin dört bir yanından benzersiz deneyimler ve rotalar
            </p>
          </div>
          <GuideCategories />
        </div>
      </div>

      {/* Popular Destinations */}
      <div className="py-16 bg-gradient-to-b from-gray-50 to-white">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Popüler Destinasyonlar
            </h2>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">
              En çok tercih edilen ve beğenilen rotalarımız
            </p>
          </div>
          <PopularDestinations />
        </div>
      </div>

      {/* Explore Section */}
      <ExploreSection />

      {/* Local Experiences */}
      <LocalExperiences />

      {/* Seasonal Guide */}
      <SeasonalGuide />

      {/* Rich Features */}
      <RichFeatures />

      {/* Regional Highlights */}
      <div className="py-16 bg-gradient-to-b from-white to-gray-50">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Bölgesel Öne Çıkanlar
            </h2>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">
              Her bölgenin kendine has güzellikleri ve keşfedilmeyi bekleyen hazineleri
            </p>
          </div>
          <RegionalHighlights />
        </div>
      </div>

      {/* Inspiration Section */}
      <InspirationSection />

      {/* Travel Tips */}
      <TravelTips />

      {/* Statistics */}
      <Statistics />

      {/* Why Choose Us */}
      <WhyChooseUs />

      {/* Testimonials */}
      <Testimonials />

      {/* Newsletter Signup */}
      <NewsletterSignup />
    </div>
  );
}