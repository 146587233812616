import React from 'react';
import { MapPin, Calendar, Star, ArrowRight } from 'lucide-react';

interface RegionHighlight {
  id: string;
  name: string;
  description: string;
  image: string;
  rating: number;
  reviewCount: number;
  bestSeason: string;
  highlights: string[];
}

const highlights: RegionHighlight[] = [
  {
    id: 'karadeniz',
    name: 'Karadeniz',
    description: 'Yeşilin bin bir tonu, yaylalar ve tarihi yapılar',
    image: 'https://images.unsplash.com/photo-1624283361978-8f99a61245a2',
    rating: 4.9,
    reviewCount: 1250,
    bestSeason: 'Yaz, Sonbahar',
    highlights: ['Uzungöl', 'Ayder Yaylası', 'Sümela Manastırı', 'Fırtına Vadisi']
  },
  // Add more regions...
];

export default function RegionalHighlights() {
  return (
    <div className="space-y-8">
      {highlights.map((region) => (
        <div 
          key={region.id}
          className="bg-white rounded-xl shadow-sm overflow-hidden hover:shadow-lg transition-all duration-300"
        >
          <div className="md:flex">
            <div className="md:w-2/5">
              <div className="relative h-64 md:h-full">
                <img
                  src={region.image}
                  alt={region.name}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/0 to-black/0" />
                <div className="absolute bottom-4 left-4">
                  <div className="flex items-center space-x-2 text-white">
                    <Star className="h-5 w-5 text-yellow-400 fill-current" />
                    <span className="font-medium">{region.rating}</span>
                    <span className="text-sm">({region.reviewCount} değerlendirme)</span>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="p-6 md:w-3/5">
              <h3 className="text-2xl font-bold text-gray-900 mb-2">{region.name}</h3>
              <p className="text-gray-600 mb-4">{region.description}</p>
              
              <div className="flex items-center space-x-4 text-sm text-gray-500 mb-4">
                <div className="flex items-center">
                  <Calendar className="h-4 w-4 mr-1 text-blue-600" />
                  <span>En iyi sezon: {region.bestSeason}</span>
                </div>
                <div className="flex items-center">
                  <MapPin className="h-4 w-4 mr-1 text-blue-600" />
                  <span>{region.highlights.length} önemli nokta</span>
                </div>
              </div>
              
              <div className="flex flex-wrap gap-2 mb-6">
                {region.highlights.map((highlight, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-sm"
                  >
                    {highlight}
                  </span>
                ))}
              </div>
              
              <button className="flex items-center text-blue-600 hover:text-blue-700 font-medium">
                <span>Detaylı Bilgi</span>
                <ArrowRight className="h-5 w-5 ml-1" />
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}