import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  LayoutDashboard, 
  Users, 
  Map, 
  Calendar, 
  MessageSquare, 
  Database,
  FileText,
  HelpCircle,
  Tag,
  Search,
  Globe,
  Shield,
  Gauge,
  Settings
} from 'lucide-react';
import { clearAdminSession } from '../../services/admin';
import Header from './Header';
import QuickActions from './QuickActions';
import SystemHealth from './SystemHealth';
import NotificationCenter from './NotificationCenter';

const navigation = [
  { name: 'Dashboard', href: '/admincaner01', icon: LayoutDashboard },
  { name: 'Üyeler', href: '/admincaner01/users', icon: Users },
  { name: 'Turlar', href: '/admincaner01/tours', icon: Map },
  { name: 'Rezervasyonlar', href: '/admincaner01/bookings', icon: Calendar },
  { name: 'Yorumlar', href: '/admincaner01/reviews', icon: MessageSquare },
  { name: 'Kampanyalar', href: '/admincaner01/campaigns', icon: Tag },
  { name: 'Raporlar', href: '/admincaner01/reports', icon: FileText },
  { name: 'Yedekleme', href: '/admincaner01/backup', icon: Database },
  { name: 'Sistem Durumu', href: '/admincaner01/system', icon: Gauge },
  { name: 'SEO Yönetimi', href: '/admincaner01/seo', icon: Globe },
  { name: 'Güvenlik', href: '/admincaner01/security', icon: Shield },
  { name: 'Ayarlar', href: '/admincaner01/settings', icon: Settings }
];

interface AdminLayoutProps {
  children: React.ReactNode;
}

export default function AdminLayout({ children }: AdminLayoutProps) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [systemStatus, setSystemStatus] = useState({
    cpu: 45,
    memory: 62,
    disk: 38,
    network: 78
  });
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate real-time system monitoring
    const interval = setInterval(() => {
      setSystemStatus(prev => ({
        cpu: Math.floor(Math.random() * 30) + 30,
        memory: Math.floor(Math.random() * 30) + 40,
        disk: Math.floor(Math.random() * 20) + 30,
        network: Math.floor(Math.random() * 40) + 40
      }));
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleLogout = () => {
    clearAdminSession();
    navigate('/admincaner01/login');
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Mobile sidebar */}
      <div className="lg:hidden">
        {isSidebarOpen && (
          <div className="fixed inset-0 z-40">
            <div
              className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
              onClick={() => setIsSidebarOpen(false)}
            />

            <div className="fixed inset-y-0 left-0 flex w-full max-w-xs flex-col bg-white">
              <div className="flex items-center justify-between px-4 py-4 border-b border-gray-200">
                <Link to="/admincaner01" className="text-xl font-bold text-gray-900">
                  Turla Admin
                </Link>
                <button
                  onClick={() => setIsSidebarOpen(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <span className="sr-only">Kapat</span>
                  <HelpCircle className="h-6 w-6" />
                </button>
              </div>

              <nav className="flex-1 overflow-y-auto p-4">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={`flex items-center px-4 py-3 text-sm font-medium rounded-lg ${
                      location.pathname === item.href
                        ? 'bg-blue-50 text-blue-600'
                        : 'text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    <item.icon className="h-5 w-5 mr-3" />
                    {item.name}
                  </Link>
                ))}
              </nav>

              {/* System Status */}
              <div className="p-4 border-t border-gray-200">
                <SystemHealth status={systemStatus} />
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Desktop sidebar */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
        <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
          <div className="flex items-center justify-between px-6 py-4 border-b border-gray-200">
            <Link to="/admincaner01" className="text-xl font-bold text-gray-900">
              Turla Admin
            </Link>
          </div>

          <nav className="flex-1 space-y-1 px-4 py-4">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={`flex items-center px-4 py-3 text-sm font-medium rounded-lg ${
                  location.pathname === item.href
                    ? 'bg-blue-50 text-blue-600'
                    : 'text-gray-700 hover:bg-gray-50'
                }`}
              >
                <item.icon className="h-5 w-5 mr-3" />
                {item.name}
              </Link>
            ))}
          </nav>

          {/* System Status */}
          <div className="p-4 border-t border-gray-200">
            <SystemHealth status={systemStatus} />
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="lg:pl-64 flex flex-col min-h-screen">
        <Header />
        
        <main className="flex-1 p-6">
          {/* Quick Actions */}
          <div className="mb-6">
            <QuickActions />
          </div>
          
          {/* Page Content */}
          {children}
        </main>
      </div>
    </div>
  );
}